import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { Link } from 'react-router-dom'
import { ButtonContent } from "../global/boton/ButtonContent";
import { FiNavigation, FiCalendar, FiClock } from 'react-icons/fi';
import Navmenu from './NavMenu';


const Header = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div>

            <div className="fondo text-center">
                <div className="md:w-full mx-auto md:flex justify-between items-center text-center">
                    <div className='mx-auto flex w-[90%] justify-between items-center'>
                        <div className='w-[100%] md:w-[20%] md:pr-0 justify-center'>
                            <Link to={'/'}>
                                <img
                                    src={`${rpdata?.dbPrincipal?.logo}`}
                                    alt='no found'
                                    className='w-[80%] '
                                />
                            </Link>
                        </div>
                        <div className='hidden mx-auto md:flex justify-between items-center text-center md:w-[80%] pl-20'>
                            <div className='hidden md:flex md:w-full'>
                                <div className='w-1/3 mx-auto md:flex'>
                                    <FiCalendar style={{ fontSize:"45px", color:"white"}} className=" border-white border-[2px] p-1 mr-3 rounded-md"/>
                                    <h5 className='text-center text-white'>{rpdata?.dbPrincipal?.workdays[0]?.day}</h5>
                                </div>
                                <div className='w-1/3 mx-auto md:flex  items-center text-center '>
                                    <FiClock style={{ fontSize:"45px", color:"white"}} className=" border-white border-[2px] p-1 mr-3 rounded-md"/>
                                    <h5 className='text-white'>{rpdata?.dbPrincipal?.workHours[0]?.hour}</h5>
                                </div>
                                <div className='w-1/3 mx-auto md:flex  items-center text-center '>
                                    <FiNavigation style={{ fontSize:"45px", color:"white"}} className="  border-white border-[2px] p-1 mr-3 rounded-md"/>
                                    <h5 className='text-white'>{rpdata?.dbPrincipal?.location[0]?.address}</h5>
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    </div>
                    {/* <ul className="flex justify-center space-x-4 pb-2 md:pb-0">
                        <li className="text-white md:text-[17px] text-[13px] hidden md:block ">
                            <span className="border-r-[1px] border-white pr-3">

                                {rpdata?.dbPrincipal?.location[0]?.address}
                            </span>
                        </li>
                        <li className="text-white md:text-[17px] text-[13px] ">
                            <span className="border-r-[1px] border-white pr-3">

                                {rpdata?.dbPrincipal?.workdays[0]?.day}
                            </span>
                        </li>
                        <li className="text-white md:text-[17px] text-[13px] ">
                            <span className="pr-3">

                                {rpdata?.dbPrincipal?.workHours[0]?.hour}
                            </span>
                        </li>
                    </ul>
                    <ul className="flex justify-center space-x-7">
                        {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                            return (
                                <li key={index} className="text-white">
                                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                        <i
                                            className={`fab fa-${item.icon}`}
                                            aria-hidden="true"
                                        />
                                    </a>
                                </li>
                            );
                        })}
                    </ul> */}
                </div>
            </div>
            <div className='bg-white md:p-4 p-1'>
            <div className='w-[100%] flex justify-between md:items-start'>
                    <nav className='w-[100%] md:w-[40%] flex  justify-end'>
                        <div className='relative'>
                            <Navmenu />
                        </div>
                    </nav>
                    <div className=' md:w-[20%] hidden md:block'>
                        <ul className="flex justify-center space-x-7">
                            {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                return (
                                    <li key={index} className="text-blue-700 ">
                                        <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                            <i
                                                className={`fab fa-${item.icon}`}
                                                aria-hidden="true" style={{fontSize:"18px"}}
                                            />
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
            </div>
            </div>
            {/* <div className='absolute right-[5%] left-[5%] z-50  bg-white rounded-[80px]'>
                <div className='w-[90%] mx-auto flex justify-between items-center'>

                    <div className='w-[80%] md:w-[20%]  pr-3 md:pr-0 justify-center'>
                        <Link to={'/'}>
                            <img
                                src={`${rpdata?.dbPrincipal?.logo}`}
                                alt='no found'
                                className='w-[90%] '
                            />
                        </Link>
                    </div>

                    <nav className='w-[20%] md:w-[60%] text-center'>
                        <div className='relative'>
                            <Navmenu />
                        </div>
                    </nav>
                    <div className='hidden md:flex md:w-[20%]  justify-end '>
                        <ButtonContent />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Header