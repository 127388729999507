import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { MdRoofing } from 'react-icons/md'


function BlockAboutOne() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-full flex justify-center md:py-32 py-10">
        <div className="max-w-7xl md:flex md:p-0 px-2 content-reverse">
          <div className="md:w-[55%]">
            <div className="w-[100%] h-[300px] md:h-[100%] flex md:flex-row justify items-center rounded-xl">
              <div className="h-[100%] w-[60%] block ">
                <div className="w-[100%] h-[50%] border-white border-[5px] shadow-lg bg-cover rounded-md mb-2" style={{backgroundImage: `url("${rpdata?.stock?.[1]}")`}}></div>
                <div className="w-[100%] h-[50%] border-white border-[5px] shadow-lg bg-cover rounded-md  " style={{backgroundImage: `url("${rpdata?.stock?.[0]}")`}}></div>
              </div>
              <div className="h-[90%] w-[40%] mx-4 mt-5 mb-5 border-white border-[6px] shadow-lg bg-center bg-cover bg-no-repeat" style={{backgroundImage: `url("${rpdata?.stock?.[5]}")`}}></div>
            </div>
            
          </div>
          <div className="md:w-[45%] py-4 md:px-6 px-3 self-center md:text-start text-center">
            <h2 className="separator-about">A Little About Us</h2>
            <p>{rpdata?.dbAbout?.[0].text}</p>
            <div className="flex md:flex-row  py-8 ">
              <div className="md:w-[50%] w-full">
                <ul className="list-inner-section pl-3 text-start">
                {
                  rpdata?.dbAbout?.[0].list.length > 1 ?
                    rpdata?.dbAbout?.[0].list.map((item, index) => {
                      return (

                        <li key={index} className="py-2 flex items-center">
                          <MdRoofing style={{fontSize:"30px"}} />
                          <span className="pl-2">{item}</span>
                        </li>
                    )
                  })
                : null
                }
              </ul>
            </div>
            
            </div>
            <ButtonContent />
            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlockAboutOne;
